import React from 'react';
import { Navigate } from 'react-router-dom';
import axios from 'axios';

const var_ = {

    url_api_local: 'http://tivaintl.com:5141/api/v1',
    token: null,
    
}

const USER: string[] = [];

const getHttpRequest = async (route: any, data: any, method = 'POST', content_type = 'application/json') => {
    
    const url = var_.url_api_local + route;
    console.log("Request : " + url)
    let config_api = {
        method: method,
        url: url,
        headers: {
            'Content-Type': content_type,
            'Authorization': (localStorage.getItem("token") == null || localStorage.getItem("token") == undefined) ? '' : `Bearer ${localStorage.getItem("token")}`,
            'accept' : 'application/json' 
        },
        data: data
    };

    return await axios.request(config_api);


};

const getHttpRequestNotToken = async (route: any, data: any, method = 'POST', content_type = 'application/json') => {
    
    const url = var_.url_api_local + route;
    // console.log("Request : " + url)
    let config_api = {
        method: method,
        url: url,
        headers: {
            'Content-Type': content_type,
            'Authorization': null,
        },
        data: data
    };

    return await axios.request(config_api);


};

const checkStatus = (status: any, navigation = null) => {

    switch (status) {
        case 403:
            var_.token = null;
            < Navigate to="Login" replace />
            break;
        case "Request failed with status code 403":
            var_.token = null;
            < Navigate to="Login" replace />
            break;
        case 401: var_.token = null;
            < Navigate to="Login" replace />
            break;
        default:
            break;
    }
}



export default { var_, getHttpRequest,getHttpRequestNotToken};