import { Space } from "antd"

import AppHeader from "../AppHeader"
import SideMenu from "../SideMenu";
import PageContent from "../PageContent";

function Container() {
  return (
    <div className="App">
          <AppHeader/>
           <div className="SideMenuAndPageContent">
               <SideMenu/>
               <PageContent/>
           </div>
       
    </div>
  );
}

export default Container;
