import api from '../../config/Config';
import toast, { Toaster } from 'react-hot-toast';
import requestRow from '../../controller/PageRow';
import { useEffect, useState, useContext, useRef } from 'react';
// import Popup from '../../components/Popup';
import { Navigate, useNavigate, Link, useParams } from 'react-router-dom';
import AuthContext from '../../authentification/context/AunthLogin';
// import QRCode from 'qrcode';

import ReactDOMServer from 'react-dom/server';
import etat from './menuvoucher/Etat'
// import { CARD_IMG } from '../../components/icons';
// import Header from '../../components/nav/Header';
import swal from 'sweetalert';
import Paginations from '../../controller/Paginations';
import {
	Card, Col, Input, Modal, Row, Badge, Dropdown, Space, Table,
	TableColumnsType,
	Popconfirm,
	Button,
	Typography,
	Drawer,
	DrawerProps
} from 'antd';
import {
	DeliveredProcedureOutlined, SearchOutlined, DownOutlined
} from '@ant-design/icons'

// import { Tabs, Tab } from '@mui/material';
// import { TabPanel, TabContext } from '@mui/lab';
// import Barcode from 'react-barcode';
// import html2canvas from 'html2canvas';
// import { saveAs } from 'file-saver';
// import domtoimage from 'dom-to-image';
// import fileSaver from 'file-saver';
// import { BarcodeProps } from 'react-barcode';


interface DataType {
	key: React.Key;
	name: string;
	platform: string;
	version: string;
	upgradeNum: number;
	creator: string;
	createdAt: string;
}

interface ExpandedDataType {
	key: React.Key;
	date: string;
	name: string;
	upgradeNum: string;
}

const items = [
	{ key: '1', label: 'Action 1' },
	{ key: '2', label: 'Action 2' },
];


function VoucherSecondary() {
	const { valueserie } = useParams();
	const data = '';

	const styleQr = {
		width: 100,
		height: 100,
	}

	const navigate = useNavigate()
	const { setAuth }: any = useContext(AuthContext);
	const [Loading, setLoading] = useState(false);
	const [page, setPage] = useState(1)
	const [listVoucher, setlistVoucher] = useState<requestRow[]>([]);
	const [searchResult, setSearchResult] = useState<requestRow[]>([]);
	const [attribVoucher, setAttribVoucher] = useState<requestRow[]>([]);
	const [idSecondary, setIdSecondary] = useState(0);

	const [query, setQuery] = useState('');
	const [sorterInfo, setSorterInfo] = useState({});

	const [nombre, setNombre] = useState('');
	const [valeur, setValeur] = useState('');
	const [nombreByPacket, setNomberByPacket] = useState('');
	const [currency, setCurrency] = useState('');
	const [nombreOfSerie, setNomberOfSerie] = useState('');
	const [idSerieCategory, setIdSerieCategory] = useState('');

	const [recuqrcode, setRecuqrcode] = useState('');
	const [recuqrcode1, setRecuqrcode1] = useState('');
	const [reqBarcode, setReqBarcode] = useState('');

	//Search
	const [searchText, setSearchText] = useState("");
	let filteredData: any[] = []

	//Drawer voucher 
	//Paginate
	const [totalPage, setTotalPage] = useState(1);

	{/** variable de la pagination */ }
	const [currentPage, setCurrentPage] = useState(1);
	const [postsPerPage] = useState(15);

	//Affichage du popup
	const [open, setOpen] = useState(false);
	const [voucherOpen, setVoucherOpen] = useState(false)
	const [openQr, setOpenQr] = useState(false);
	function handleClickOpen() {
		setOpen(true);
	};

	const [size, setSize] = useState<DrawerProps['size']>();
	const showLargeDrawer = () => {
		setSize('large');
		setVoucherOpen(true);
		// setIdSerieCategory(id)
	};

	const onClose = () => {
		setVoucherOpen(false);
	};


	const [confirmLoading, setConfirmLoading] = useState(false);
	const [modalText, setModalText] = useState('Content of the modal');

	const showModal = () => {
		setOpen(true);
	};

	// const handleOk = () => {
	// 	setModalText('The modal will be closed after two seconds');
	// 	setConfirmLoading(true);
	// 	setTimeout(() => {
	// 		setOpen(false);
	// 		setConfirmLoading(false);
	// 	}, 2000);
	// };

	const handleCancel = () => {
		console.log('Clicked cancel button');
		setOpen(false);
	};

	// const handleClickOpenQr = async (element: any) => {
	// 	setOpenQr(true);
	// 	setRecuqrcode1(element.series_voucher)
	// 	setReqBarcode(element.series_voucher)
	// 	try {
	// 		const response = await QRCode.toDataURL(element.series_voucher)
	// 		setRecuqrcode(response)

	// 	} catch (error) {
	// 		console.log(error);
	// 	}
	// };

	//downloadBarcode 
	// const barcodeProps: BarcodeProps = {
	// 	value: reqBarcode,

	// };

	function downloadBarcode(val: any) {
		const canvas = document.getElementById(val) as HTMLCanvasElement;
		if (canvas == null) {
			console.error('Canvas is null');
			return;
		}

		const dataUrl = canvas.toDataURL() ?? '';
		const link = document.createElement('a');
		link.href = dataUrl;
		link.download = 'barcode.png';
		document.body.appendChild(link);
		link.click();
	}


	useEffect(() => {
		if (!localStorage.getItem('token') || localStorage.getItem('token') == null) {
			navigate('/signin')
		}

		List(1);

	}, []);

	const List = (page: any) => {
		setLoading(true);
		api.getHttpRequest(`/voucher/serie/secondary/${valueserie}/${page}`, data, 'GET').then((response) => {
			const result = response.data.data
			setlistVoucher(result.rows);
			setSearchResult(result.rows);
			setTotalPage(result.count);
			setLoading(false);
		}).catch((error) => {
			toast.error(error.message);
		})
	}

	const ListVoucher = async (id: any) => {
		setLoading(true);
		setIdSecondary(id)
		api.getHttpRequest(`/voucher/all/${id}/1`, data, 'GET').then((response) => {
			const result = response.data.data
			setAttribVoucher(result)
			setLoading(false);
		}).catch((error) => {
			toast.error(error.message);
		})

	}



	//function generate voucher
	const genarateVoucher = () => {
		let data = JSON.stringify({
			"number": parseInt(nombre),
			"amount": parseInt(valeur),
			"number_by_packet": parseInt(nombreByPacket),
			"currency": currency,
			"number_of_series": parseInt(nombreOfSerie)
		});


		if (nombre === null || valeur === null || nombreByPacket === null || currency === '') {
			toast.error("The number, value, and number of packets fields must not be empty")
		} else {
			api.getHttpRequest('/vouchers/create', data, 'POST').then((response) => {
				if (response.data.status == 200) {
					setNombre('');
					setValeur('');
					setNomberByPacket('');
					toast.success(response.data.message);
					navigate(`/serie_by_category/${response.data.data}`)
					setOpen(false);
				} else {
					toast.error(response.data.message);
				}
			}).catch((error) => {
				toast.error(error.message);
			})
		}
	}

	//function export voucher
	// function exportVoucherPrincipal(item: any) {
	// 	let data = JSON.stringify({
	// 		"type": "principal",
	// 		"serie": item
	// 	});

	// 	// setLoading(true);

	// 	api.getHttpRequest('/vouchers/export', data, 'POST').then((response) => {
	// 		if (response.data.status == 200) {
	// 			toast.success(response.data.message);
	// 			List();
	// 			setLoading(true);
	// 		} else {
	// 			// setLoading(false);
	// 			toast.error(response.data.message);
	// 		}
	// 	}).catch((error) => {
	// 		// setLoading(false);
	// 		toast.error(error.message);
	// 	})

	// 	setTimeout(() => {
	// 		setLoading(false);
	// 	}, 3000);
	// }

	//function delete serie
	// const handleDelete = (element: any) => {
	// 	swal({
	// 		title: `Are you sure, you want to delete this series ${element.series_voucher} ?`,
	// 		text: "Once deleted, the series will not be visible on the interface!",
	// 		icon: "warning",
	// 		buttons: ["Cancel", "Delete"],
	// 		dangerMode: true,
	// 	})
	// 		.then((willDelete: any) => {
	// 			if (willDelete) {
	// 				api.getHttpRequest(`/vouchers/delete/${element.series_voucher}`, data, 'delete').then((response) => {
	// 					if (response.data.status == 200) {
	// 						swal(response.data.message, {
	// 							icon: "success",
	// 						});
	// 						List();
	// 					} else {
	// 						swal(response.data.message, {
	// 							icon: "warning",
	// 						});
	// 					}
	// 				}).catch((error) => {
	// 					swal(error.message, {
	// 						icon: "warning",
	// 					});
	// 				})

	// 			} else {
	// 				swal("The series will be kept!");
	// 			}
	// 		});

	// }


	{/**
    function
 */}
	const getShortVouch = (r: any) => {
		return '**********' + r.substring((r.length - 4), r.length)
	}

	const getUsed = (x: any) => {
		if (x == 1) return ['badge light badge-danger', 'Already use'];
		if (x == 0) return ['badge light badge-success', 'Not use'];
		return '';
	}

	//Formate Date
	function geDateFormat(dateString: string): string {
		const date = new Date(Date.parse(dateString));
		const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
		const formattedDate = date.toLocaleDateString('en-US', options);
		return formattedDate;
	}

	//search to Input 
	const searchToInput = (event: any) => {
		const getSearch = event.target.value;
		if (getSearch.length > 0) {
			const searchdata = currentPosts.filter((item) => item.series_voucher.toLowerCase().includes(getSearch));
			setlistVoucher(searchdata)
		} else {
			setlistVoucher(searchResult)
		}
		setQuery(getSearch);
	}

	const options = [
		{
			label: 'USD',
			value: 'USD'
		},
		{
			label: `TZS-Tanzanian Shilling`,
			value: 'TZS'
		},
		{
			label: 'CDF-Congolese franc',
			value: 'CDF'
		}
	]

	// fucntion count serie voucher
	function countSeriesVoucher(array: any) {
		var count = 0;
		for (var i = 0; i < array.length; i++) {
			var obj = array[i];
			var series_voucher = obj.series_voucher;
			if (series_voucher) {
				count++;
			}
		}
		return count;
	}

	//Get current list page
	const indexOfLastPost = currentPage * postsPerPage;
	const indexOfFirstPost = indexOfLastPost - postsPerPage;
	const currentPosts = listVoucher.slice(indexOfFirstPost, indexOfLastPost)
	const howManyPages = Math.ceil(listVoucher.length / postsPerPage);

	//Fuction qui me permet de faire le scrolle
	const [valueQrBar, setValueGrBar] = useState('1');

	const handleChange = (event: React.SyntheticEvent, newValue: string) => {
		setValueGrBar(newValue);
	};

	//Antd table

	const expandedRowRender = () => {
		const columns: TableColumnsType<ExpandedDataType> = [
			{ title: 'Date', dataIndex: 'date', key: 'date' },
			{ title: 'Name', dataIndex: 'name', key: 'name' },
			{
				title: 'Status',
				key: 'state',
				render: () => <Badge status="success" text="Finished" />,
			},
			{ title: 'Upgrade Status', dataIndex: 'upgradeNum', key: 'upgradeNum' },
			{
				title: 'Action',
				dataIndex: 'operation',
				key: 'operation',
				render: () => (
					<Space size="middle">
						<a>Pause</a>
						<a>Stop</a>
						<Dropdown menu={{ items }}>
							<a>
								More <DownOutlined />
							</a>
						</Dropdown>
					</Space>
				),
			},
		];

		const data_ = [];
		for (let i = 0; i < 3; ++i) {
			data_.push({
				key: i.toString(),
				date: '2014-12-24 23:12:00',
				name: 'This is production name',
				upgradeNum: 'Upgraded: 56',
			});
		}
		return <Table columns={columns} dataSource={data_} pagination={false} />;
	};

	//   const columns: TableColumnsType<DataType> = [
	// 	{ title: 'Name', dataIndex: 'name', key: 'name' },
	// 	{ title: 'Platform', dataIndex: 'platform', key: 'platform' },
	// 	{ title: 'Version', dataIndex: 'version', key: 'version' },
	// 	{ title: 'Upgraded', dataIndex: 'upgradeNum', key: 'upgradeNum' },
	// 	{ title: 'Creator', dataIndex: 'creator', key: 'creator' },
	// 	{ title: 'Date', dataIndex: 'createdAt', key: 'createdAt' },
	// 	{ title: 'Action', key: 'operation', render: () => <a>Publish</a> },
	//   ];

	const data_1: DataType[] = [];
	for (let i = 0; i < 3; ++i) {
		data_1.push({
			key: i.toString(),
			name: 'Screen',
			platform: 'iOS',
			version: '10.3.4.5654',
			upgradeNum: 500,
			creator: 'Jack',
			createdAt: '2014-12-24 23:12:00',
		});
	}

	const dataVoucher = listVoucher.map((item, i) => ({
		...item,
		key: i,
		details: `Date of creation ${geDateFormat(item.createdAt)},  Voucher Serie Id ${item.vouchersSeryId}`
	}))

	const dataVoucher_Attibute = attribVoucher.map((item, i) => ({
		...item,
		key: i,
		details: `Date of creation ${geDateFormat(item.createdAt)},  Voucher Serie Id ${item.used}`
	}))

	const handleDelete = (value: any) => {
		const dataSource = [...dataVoucher]
		const filteredData = dataSource.filter((item) => item.serie !== value.serie)
		setlistVoucher(filteredData)
	}

	const handleUrl = (value: any) => {
		const dataSource = [...dataVoucher]
		const filteredData = dataSource.filter((item) => item.id !== value.id)
		setlistVoucher(filteredData)
	}

	const serie_secondary = (value: any) => {
		const dataSource = [...dataVoucher]
		const filteredData = dataSource.filter((item) => item.serie !== value.serie)

		setLoading(true);
		api.getHttpRequest(`/voucher/serie/secondary/${filteredData}/2`, data, 'GET').then((response) => {
			const result = response.data.data
			setlistVoucher(result);
			setSearchResult(result);
			setLoading(false);
		}).catch((error) => {
			toast.error(error.message);
		})
	}

	const columnsVoucher: TableColumnsType<requestRow> = [
		{
			title: "Id",
			dataIndex: "id",
		},
		{
			title: "Serie",
			dataIndex: "serie",
			align: "center",
		},
		{
			title: "Packet",
			dataIndex: "number_by_packet",
			align: "center",
		},

		{
			title: "Serie Id",
			dataIndex: "vouchersSeryId",
			align: "center",
		},

		{
			title: "Action",
			dataIndex: "action",
			align: "center",
			render: (_, record) =>
				dataVoucher.length >= 1 ? (
					<Space>
						<Button onClick={() => ListVoucher(record.id)}><i className="fas fa-eye"></i></Button>
						{/* <Popconfirm title="Are you sure want to delete ?" onConfirm={() => handleDelete(record)}>
							<Button danger type='primary'>Delete</Button>
						</Popconfirm> */}
					</Space>

				) : null
		},
	]

	const columnsVoucher01: TableColumnsType<requestRow> = [
		{
			title: "Date of creation",
			dataIndex: "createdAt",
		},
		{
			title: "Voucher Serie Id",
			dataIndex: "vouchersSeryId",
			align: "center",
		},

	]

	const columnsVoucher_Attribute: TableColumnsType<requestRow> = [
		{
			title: "#",
			dataIndex: "id",
		},
		{
			title: "Serie unique",
			dataIndex: "serie_unique",
			align: "center",
		},
		{
			title: "Pin",
			dataIndex: "pin",
			align: "center",
		},
	]

	function actualiser() {
		setSorterInfo({});
		setSearchText("");
		List(1)
	};

	const handleChangeVoucher = (e: any) => {
		setSearchText(e.target.value);
		if (e.target.value === "") {
			List(1);
		}
	};

	const globalSearch = () => {
		filteredData = dataVoucher.filter((value) => {
			return (
				typeof value.serie === 'string' && value.serie.toLowerCase().includes(searchText.toLowerCase()) ||
				typeof value.amount === 'string' && value.amount.toLowerCase().includes(searchText.toLowerCase())
			);
		});
		setlistVoucher(filteredData);
	};



	return (
		<>


			<div className="container-admin">
				<div className="page-container">
					<Space direction='vertical'>
						<Space >
							<Card style={{ width: '85%', opacity: 0.9, position: 'fixed', zIndex: 1 }}>

								<Row >
									<Col span={8} >
										<Space direction="horizontal">

											<DeliveredProcedureOutlined
												style={{
													color: "green",
													backgroundColor: "rgba(0,255,0,0.25)",
													borderRadius: 20,
													fontSize: 24,
													padding: 8

												}}
											/>
											<div className="me-auto mb-sm-0 ">
												<h3>Count: {countSeriesVoucher(listVoucher)}</h3>
											</div>
										</Space>
									</Col>

									<Col span={6}></Col>

									<Col span={10}>
										<Space>

											<div >
												<Input size="large" placeholder="Searh here..." prefix={<SearchOutlined />}
													onChange={handleChangeVoucher}
													value={searchText}
													allowClear
													type='text'
												/>
											</div>

											<button onClick={globalSearch} type="button" className="btn btn-rounded btn-primary" >

												Seach
											</button>

											<button onClick={actualiser} type="button" className="btn btn-rounded btn-primary" >

												Reset
											</button>


											{/* <button onClick={showModal} type="button" className="btn btn-rounded btn-primary" >
	<span className="btn-icon-left text-primary"><i className="fa fa-plus" aria-hidden="true"></i></span>
	Generate vouchers
</button> */}

										</Space>
									</Col>

								</Row>
							</Card>
						</Space>

						<div style={{ marginTop: 100 }}>
							<Toaster
								position="bottom-left"
								reverseOrder={false}
							/>

							<Row >
								<Col span={14}>
									<Card >
										<Typography style={{ fontSize: 20 }}>Series secondary {valueserie}</Typography>
										<>
											<Table
												columns={columnsVoucher}
												dataSource={filteredData && filteredData.length ? filteredData : dataVoucher}
												size="small"
												expandable={{
													rowExpandable: (record) => record.details !== "Not Expandable",
													expandedRowRender: (record) => (

														<Card style={{ margin: 0 }}>{record.details}</Card>
													)
												}}

												loading={Loading}
												pagination={{
													pageSize: 10,
													total: totalPage,
													onChange: (page: any) => {
														List(page)
													}
												}}
											/>
										</>
									</Card>
								</Col>
								{/* <Col span={1}></Col> */}

								<Col span={10}>

									<Card >
										<Typography style={{ fontSize: 20 }}>Vouchers {idSecondary}</Typography>
										<>
											<Table
												columns={columnsVoucher_Attribute}
												dataSource={dataVoucher_Attibute}
												size="small"
											// expandable={{
											// 	rowExpandable: (record) => record.details !== "Not Expandable",
											// 	expandedRowRender: (record) => (

											// 		<Card style={{margin:0}}>{record.details}</Card>
											// 	)
											// }}
											/>
										</>

									</Card>

								</Col>


							</Row>

						</div>
					</Space>
				</div>
			</div>


			{/* <Popup
				title='Generate voucher'
				openPupop={open}
				setOpenPopup={setOpen}
			>

				<div className="basic-form">
					<div>
						<div className="mb-3 row">
							<label className="col-sm-3 col-form-label">Number</label>
							<div className="col-sm-9">
								<input type="number" className="form-control" placeholder="Number"
									name='nombre'
									value={nombre}
									onChange={(e) => { setNombre(e.target.value) }}
								/>
							</div>
						</div>
						<div className="mb-3 row">
							<label className="col-sm-3 col-form-label">Value</label>
							<div className="col-sm-9">
								<input type="number" className="form-control" placeholder="Value"
									name='valeur'
									value={valeur}
									onChange={(e) => { setValeur(e.target.value) }}
								/>
							</div>
						</div>
						<div className="mb-3 row">
							<label className="col-sm-3 col-form-label">Number by packet</label>
							<div className="col-sm-9">
								<input type="number" className="form-control" placeholder="Number by packet"
									name='nombreByPacket'
									value={nombreByPacket}
									onChange={(e) => { setNomberByPacket(e.target.value) }}
								/>
							</div>
						</div>

						<div className="mb-3 row">
							<label className="col-sm-3 col-form-label">Currency</label>
							<div className="col-sm-9">
								<select className="form-control" placeholder="number by packet"
									name='nombreByPacket'
									value={currency}
									onChange={(e) => { setCurrency(e.target.value) }}
								>
									<option value="" style={{ margin: 5 }}>Choose currency</option>
									{options.map((option, i) => (
										<option key={i} value={option.value}>{option.label}</option>
									))}
								</select>
							</div>
						</div>

						<div className="mb-3 ">
							<div className="col-sm-10">
								<button onClick={() => genarateVoucher()} type="submit" className="btn btn-primary">Generate</button>
							</div>
						</div>
					</div>
				</div>

			</Popup> */}

			{/* <Popup
				title={'Download : ' + recuqrcode1}
				openPupop={openQr}
				setOpenPopup={setOpenQr}
			>

				<>
					<div className="profile-tab">
						<div className="custom-tab-1">
							<TabContext value={valueQrBar}>
								<Tabs
									value={valueQrBar}
									onChange={handleChange}
									variant="scrollable"
									scrollButtons="auto"
									aria-label="scrollable auto tabs example"
									sx={{ bgcolor: '#FFFFFF' }}
								>
									<Tab
										label="QrCode"
										value='1'

									/>

									<Tab sx={{ pl: 6 }} value='2' label="BarCode" />
								</Tabs>
								<TabPanel value='1'>
									<div className="col-xl-12 col-lg-12 col-sm-12">
										<div className="card overflow-hidden">
											<div className="text-center p-3  " style={{ backgroundColor: '#F0F1F3' }}>
												<div className="profile-photo">
													<img src={recuqrcode} width="150" className="" alt="" />
												</div>
											</div>

											<div className="card-footer border-0 mt-0">
												<a href={recuqrcode} className="btn btn-primary btn-lg btn-block" download={recuqrcode1}>
													<i className="fas fa-download"></i> Downloard Qr code
												</a>
											</div>
										</div>
									</div>
								</TabPanel>
								<TabPanel value='2'>
									<div className="col-xl-12 col-lg-12 col-sm-12">
										<div className="card overflow-hidden" id="my-node">
											<div className="text-center p-3  " style={{ backgroundColor: '#F0F1F3' }}>
												<div id="#barcode" className="profile-photo">

													<Barcode {...barcodeProps} />

												</div>
												<div>

												</div>
											</div>

											<div className="card-footer border-0 mt-0">
												<button onClick={() => downloadBarcode(reqBarcode)} className="btn btn-primary btn-lg btn-block" >
													<i className="fas fa-download"></i> Downloard BarCode
												</button>
											</div>
										</div>
									</div>
								</TabPanel>
							</TabContext>


					
						</div>
					</div>
				</>


			</Popup> */}

			<Modal
				title="Generate voucher"
				open={open}
				// onOk={handleOk}
				// confirmLoading={confirmLoading}
				onCancel={handleCancel}
			>
				<div className="basic-form">
					<div>
						<div className="mb-3 row">
							<label className="col-sm-3 col-form-label">Number</label>
							<div className="col-sm-9">
								<input type="number" className="form-control" placeholder="Number"
									name='nombre'
									value={nombre}
									onChange={(e) => { setNombre(e.target.value) }}
								/>
							</div>
						</div>
						<div className="mb-3 row">
							<label className="col-sm-3 col-form-label">Value</label>
							<div className="col-sm-9">
								<input type="number" className="form-control" placeholder="Value"
									name='valeur'
									value={valeur}
									onChange={(e) => { setValeur(e.target.value) }}
								/>
							</div>
						</div>
						<div className="mb-3 row">
							<label className="col-sm-3 col-form-label">Number by packet</label>
							<div className="col-sm-9">
								<input type="number" className="form-control" placeholder="Number by packet"
									name='nombreByPacket'
									value={nombreByPacket}
									onChange={(e) => { setNomberByPacket(e.target.value) }}
								/>
							</div>
						</div>

						<div className="mb-3 row">
							<label className="col-sm-3 col-form-label">Number of series</label>
							<div className="col-sm-9">
								<input type="number" className="form-control" placeholder="Number of Series"
									name='nombreOfSerie'
									value={nombreOfSerie}
									onChange={(e) => { setNomberOfSerie(e.target.value) }}
								/>
							</div>
						</div>

						<div className="mb-3 row">
							<label className="col-sm-3 col-form-label">Currency</label>
							<div className="col-sm-9">
								<select className="form-control" placeholder="number by packet"
									name='nombreByPacket'
									value={currency}
									onChange={(e) => { setCurrency(e.target.value) }}
								>
									<option value="" style={{ margin: 5 }}>Choose currency</option>
									{options.map((option, i) => (
										<option key={i} value={option.value}>{option.label}</option>
									))}
								</select>
							</div>
						</div>

						<div className="mb-3 ">
							<div className="col-sm-10">
								<button onClick={() => genarateVoucher()} type="submit" className="btn btn-primary">Generate</button>
							</div>
						</div>
					</div>
				</div>
			</Modal>


			<Drawer
				title={`Drawer`}
				placement="right"
				size={size}
				onClose={onClose}
				open={voucherOpen}
				extra={
					<Space>
						<Button onClick={onClose}>Cancel</Button>
						<Button type="primary" onClick={onClose}>
							OK
						</Button>
					</Space>
				}
			>
				<p>Some contents...</p>
				<p>Some contents...</p>
				<p>Some contents...</p>
			</Drawer>

		</>
	)
}

export default VoucherSecondary
