import { Menu } from "antd"
import {
    AppstoreOutlined,
    ShopOutlined,
    SettingOutlined,
    ShoppingCartOutlined,
    UserOutlined
} from '@ant-design/icons';
import {useLocation, useNavigate} from "react-router-dom";
import { useEffect, useState } from "react";

function SideMenu() {
     const location = useLocation()
     const [selectedKeys, setSelectedKeys] = useState('/')

     useEffect(() => {
        const pathName = location.pathname
        setSelectedKeys(pathName)
     }, [location.pathname])

    const navigate = useNavigate()
    return (
      <div className="SideMenu">
        
        <Menu
          className="SideMenuVertical"
          mode="vertical"
          onClick={(item) => {
            navigate(item.key)  
          }}


        
          selectedKeys ={[selectedKeys]}
          items ={
             [
               {
                   label: "Dashboard",
                   icon: <AppstoreOutlined />,
                   key: "/dashboard",  
               },
               {
                   label: "Voucher",
                   key: "/voucher",
                   icon: <ShopOutlined />
               },
               {
                   label : "Settings",
                   key : "/settings",
                   icon: <SettingOutlined />
               },
               
               
           
               
             ]
          }

          style={{fontSize:20}}
        > 
               
        </Menu>
      </div>
      )
}

export default SideMenu;