import React, { Suspense } from 'react';
import './assets/css/style.css';
import { HashRouter, Route, Routes } from 'react-router-dom';
import Login from './authentification/Login';
import Out from './views/notfound/Out';
import Signup from './authentification/Signup';
import ForgotPassword from './authentification/ForgotPassword';

import Container  from './component/Container'

function App() {
  return (
    <>
      <HashRouter>
        <Suspense>
          <Routes>
            <Route path='/' element={<Out />} />
            <Route index element={<Login />} />
            <Route path='/signin'  element={<Login />} />
            <Route path='/signup' element={<Signup />} />
            <Route path='/forgot-password' element={<ForgotPassword />} />
            <Route path='*' element={<Container />} />
            <Route path='*' element={<Out />} />
          </Routes>
        </Suspense>
      </HashRouter>
    </>
  );
}

export default App;
