import { logo3 } from "../icons";
import {
    BellFilled, FacebookFilled, MailOutlined, SearchOutlined,
    MessageOutlined, UserOutlined, DownOutlined
} from '@ant-design/icons'
import {
    Badge, Drawer, Image, List, Space, Typography,
    Input, Dropdown, Avatar, Layout
} from "antd";
import { useEffect, useState } from "react";
import styled from 'styled-components';
import ProfileMenu from "../ProfileMenu";

const { Header } = Layout

function AppHeader() {
    const item = localStorage.getItem('user');


    const [comments, setComments] = useState([])
    const [orders, setOrders] = useState([])
    const [commentsOpen, setCommentsOpen] = useState(false)
    const [notificationsOpen, setNotificationsOpen] = useState(false)
    const [MessagerOpen, setMessegerOpen] = useState(false)

    return (
        <>
            <div className="AppHeader" >
                <Space>
                    <Image
                        width={230}
                        src={logo3}
                    >
                    </Image>

                    <Input size="large" placeholder="Search here..." prefix={<SearchOutlined />}
                        className="input-search-header"
                    />
                </Space>


                <Space>

                    <Badge count={2}
                        size="small" style={{ backgroundColor: '#004BFF', marginRight: 5 }}
                    >
                        <BellFilled className="notification"
                            style={{ fontSize: 20 }}
                            onClick={() => {
                                setNotificationsOpen(true)
                            }}
                        />
                    </Badge>

                    <Badge count={2}
                        size="small" style={{ backgroundColor: '#004BFF', marginRight: 5 }}
                    >
                        <MessageOutlined className="notification"
                            style={{ fontSize: 20 }}
                            onClick={() => {
                                setMessegerOpen(true)
                            }}
                        />
                    </Badge>

                    <Dropdown overlay={<ProfileMenu />} trigger={['click']}>
                        <StyledProfileMenu>
                            <Avatar shape="circle" icon={<UserOutlined />} style={{ marginRight: 5 }} />
                            {'   '}
                            <StyledUserFullname>{item}</StyledUserFullname>
                            <DownOutlined style={{ marginLeft: 15 }} />
                        </StyledProfileMenu>
                    </Dropdown>


                </Space>


            </div>

            <Drawer title="Notifications" open={notificationsOpen}
                onClose={() => { setNotificationsOpen(false) }}
                maskClosable>

                <List
                >
                    Supplier UNPAID on Next Oder
                </List>
            </Drawer>

            <Drawer title="Notifications2" open={MessagerOpen}
                onClose={() => { setMessegerOpen(false) }}
                maskClosable>

                <List

                >
                    Suplier UNPAID Invoices
                </List>
            </Drawer>
        </>
    )
}

const StyledMenu = styled('span')`
  user-select: none;
  cursor: pointer;
`

const StyledSettingsMenu = styled(StyledMenu)``

const StyledProfileMenu = styled(StyledMenu)`
  background: #fff;
  height: 100%;
  display: inline-block;
  padding: 0 5px;
`

const StyledUserFullname = styled('span')`
  margin-right: 5px;
`

const StyledHeader = styled(Header)`
  background: #fff;
  padding: 0;
  paddingleft: 10px;
  min-width: 470px;
  max-width: 100%;

  @media only screen and (max-width: 470px) {
    display: none;
  }
`

const StyledUserActions = styled('div')`
  background: #fff;
  margin-right: 10px;
  float: right;

  @media only screen and (max-width: 320px) {
    background: #000;
    color: #fff;
  }

  @media only screen and (max-width: 780px) {
    display: none;
  }
`

export default AppHeader;