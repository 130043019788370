
import { createContext, useState } from "react";

const AuthContext = createContext({});

export interface props {
    children?: React.ReactNode;}

export const AunthLogin = ({children}: props) => {
    const [auth, setAuth] = useState({});
    // let user_ = localStorage.getItem("token");
    return (
        <AuthContext.Provider value={{ auth, setAuth }}>     
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext;