
import React, { useEffect, useState } from 'react';
import { Card, Col, Row, Space, Statistic, Typography } from "antd"
import { logo1 } from '../component/icons';
import { useNavigate } from 'react-router-dom';
import {
    ShoppingCartOutlined, ShoppingOutlined, UserOutlined, DollarCircleOutlined
} from '@ant-design/icons'
import api from '../config/Config';
import requestRow from '../controller/PageRow';

const Home: React.FC = () => {

    const navigate = useNavigate();

    const data = '';
    const [dashboard, setDashboard] = useState<requestRow[]>([])
    const [country, setCountry] = useState('')
    const [series, setSeries] = useState('')
    const [sousCountry, setSousCountry] = useState('')
    const [allVoucher, sstAllVoucher] = useState('')
    const [serieTransfer, setSerieTransfer] = useState('')

    useEffect(() => {
        if (!localStorage.getItem('token') || localStorage.getItem('token') == null) {
            navigate('/signin')
        }
        loadDashboard()
    }, []);

    const loadDashboard = () => {
        api.getHttpRequest('/dashboard', data, 'GET')
            .then(response => {
                const json = response.data.data;
                setCountry(json.count_country)
                setSeries(json.count_series)
                setSousCountry(json.count_sous_series)
                sstAllVoucher(json.count_all_vouchers)
                setSerieTransfer(json.count_vouchers_transferd)
            })
            .catch(err => console.log(err));
    }

    return (
        <>
            <div className="container-admin">
                <div className="page-container">
                    <Space direction='vertical' >
                        <Typography.Title level={4} style={{ paddingTop: 20 }}>Dashbord</Typography.Title>
                        
                            <Row gutter={[8, 8]}>
                                <Col xs={24} sm={12} md={8} lg={6}>
                                    <DashboardCard icon={<ShoppingCartOutlined
                                        style={{
                                            color: "green",
                                            backgroundColor: "rgba(0,255,0,0.25)",
                                            borderRadius: 20,
                                            fontSize: 24,
                                            padding: 8

                                        }}
                                    />}
                                        title={"All Countries"} value={country} />
                                </Col>

                                <Col xs={24} sm={12} md={8} lg={6}>
                                    <DashboardCard icon={<ShoppingOutlined
                                        style={{
                                            color: "blue",
                                            backgroundColor: "rgba(0,0,255,0.25)",
                                            borderRadius: 20,
                                            fontSize: 24,
                                            padding: 8
                                        }}
                                    />} title={"All Series"} value={series} />
                                </Col>

                                <Col xs={24} sm={12} md={8} lg={6}>
                                    <DashboardCard icon={<UserOutlined
                                        style={{
                                            color: "purple",
                                            backgroundColor: "rgba(0,255,255,0.25)",
                                            borderRadius: 20,
                                            fontSize: 24,
                                            padding: 8
                                        }}
                                    />} title={"Secondary Series"} value={sousCountry} />
                                </Col>

                                <Col xs={24} sm={12} md={8} lg={6}>
                                    <DashboardCard icon={<DollarCircleOutlined
                                        style={{
                                            color: "red",
                                            backgroundColor: "rgba(255,0,0,0.25)",
                                            borderRadius: 20,
                                            fontSize: 24,
                                            padding: 8
                                        }}
                                    />} title={"All Vouchers"} value={allVoucher} />
                                </Col>

                                <Col xs={24} sm={12} md={8} lg={6}>
                                    <DashboardCard icon={<DollarCircleOutlined
                                        style={{
                                            color: "red",
                                            backgroundColor: "rgba(255,0,0,0.25)",
                                            borderRadius: 20,
                                            fontSize: 24,
                                            padding: 8
                                        }}
                                    />} title={"Series Transfered"} value={serieTransfer} />
                                </Col>
                            </Row>
                        

                    </Space>

                </div>
            </div>

        </>
    );
};

function DashboardCard({ title, value, icon }: any) {
    return (
        <Card>
            <Space direction="horizontal">
                {icon}
                <Statistic title={title} value={value} />
            </Space>

        </Card>
    )
}

export default Home;