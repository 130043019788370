import React, { useRef, useState, useEffect, useContext } from "react";

import { Link, useNavigate } from "react-router-dom";
import api from '../config/Config'
import toast, { Toaster } from 'react-hot-toast';
import { logo1 } from "../component/icons";
import AuthContext from "./context/AunthLogin";


const Login = () => {
    const navigate = useNavigate()
    const { setAuth } = useContext(AuthContext);
    const userRef = useRef();
    const [Loading, setLoading] = useState(false);

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);

    

   
    const loginSubmit = async (e) => {
        e.preventDefault();

        var data = JSON.stringify({
            "email": username,
            "password": password
        });

        setLoading(true);

        if (username === '' || password === '') {
            toast.error('The Email and Password fields must not be empty');

        } else {
            api.getHttpRequestNotToken("/auth/login", data, 'POST').then((response) => {
                const json = response.data;
                if (json.status == 200) {
                    const accessToken = response.data.token;
                    const roles = response.data.roles;
                    // setAuth({ username, password, accessToken, roles })
                    setUsername('');
                    setPassword('');
                    setSuccess(true);
                    // localStorage.setItem('info_user', JSON.stringify(json.data));
                    localStorage.setItem('token', json.data.token);
                    localStorage.setItem('user', json.data.username);

                    navigate('/dashboard')
                } else {
                    toast.error(json.message);
                }
            }).catch((error) => {
                // setLoading(false);
                toast.error(error.message);
            })
        }

        setTimeout(() => {
            setLoading(false);
        }, 3000);
    }

    return (

        <React.Fragment>

            <div className="auth-multi-layout">
                <div className="auth-box">
                    <div className="auth-header">
                        <div className="auth-header-logo">
                            <img src={logo1} alt="" className="auth-header-logo-img" />
                        </div>
                        <p className="auth-header-subtitle">
                            Login to International
                        </p>
                    </div>
                    <div className="auth-body">
                        <div className="auth-form-validation">
                            <div>
                                <Toaster 
                                  position="bottom-left"
                                  reverseOrder={false}
                                />
                            </div>
                            <div className="input-field">
                                <label htmlFor="email" className="input-label">
                                    Username or email
                                </label>

                                <input type="text" className="form-control" id="email" placeholder="Enter email address or phone number"
                                    autoComplete="off"
                                    required name="username"
                                     onChange={(e) => setUsername(e.target.value)}
                                    value={username}
                                />
                            </div>
                            <div className="input-field">
                                <label htmlFor="password" className="input-label">
                                    Password
                                </label>
                                <input type="password" name="password" id="password" className="form-control" placeholder="Password"
                                    autoComplete="off"
                                    onChange={(e) => setPassword(e.target.value)}
                                    value={password}
                                />
                            </div>
                            <div className="input-field">
                                <div className="checkbox">
                                    <label>
                                        <input id="login-remember" type="checkbox" name="remember" /> Remember me
                                    </label>
                                </div>
                            </div>

                            <div className="flex-end">
                                <Link to={"/forgot-password"} className="link-end">
                                    Forgot password?
                                </Link>
                            </div>

                            {!Loading && (
                                <button type="submit" className="btn-submit" onClick={loginSubmit}>
                                    Sign in
                                </button>
                            )}

                            {Loading && (

                                <button type="submit" className="btn-submit" disabled>
                                    <i className="fa fa-spinner fa-spin fa-3x  fa-lg"></i> <span>Ongoing processing...</span>
                                </button>
                            )}

                        </div>
                        <p className="text-center">
                            Don't have an account!{" "}
                            <Link to={"/signup"} className="link-text-center">
                                Sign Up Here
                            </Link>
                        </p>
                    </div>
                </div>
            </div>

        </React.Fragment>
    );
};

export default Login;
