import { Outlet } from "react-router-dom";

const Out = () => {
   return (
      <>
         <Outlet/>
      </>
   )
}

export default Out;