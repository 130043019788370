import React, { useEffect, useState } from 'react'
import { Card, Col, Input, Modal, Row, Space, Tabs, Form, Radio, Button, Select, Popconfirm } from 'antd';
import {
    SettingOutlined, SearchOutlined, InfoCircleOutlined, CloseOutlined, DeleteOutlined
} from '@ant-design/icons'
import { useNavigate } from 'react-router-dom';
import api from '../../../config/Config';
import requestRow from '../../../controller/PageRow';
import toast, { Toaster } from 'react-hot-toast';


const Roles = () => {

    const data = '';

    const navigate = useNavigate();

    const [Loading, setLoading] = useState(false);
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [id, setId] = useState('');
    const [loadRole, setloadRoles] = useState<requestRow[]>([])
    const [vBottom, setVBottom] = useState('');



    useEffect(() => {
        if (!localStorage.getItem('token') || localStorage.getItem('token') == null) {
            navigate('/signin')
        }

        LoardRoles()

    }, [])

    const LoardRoles = () => {
        api.getHttpRequest('/roles', data, 'GET')
            .then(response => {
                setloadRoles(response.data.data);
            })
            .catch((error) => {
                toast.error(error.message);
            })
    }




   /**All button action */
    function New_roles() {

        let data = JSON.stringify({
            "name": name,
            "description": description
        });


        setLoading(true);
        api.getHttpRequest('/roles', data, 'POST').then((response) => {
            if (response.data.status == 201) {

                toast.success(response.data.message);
                navigate(`/serie_by_category/${response.data.data}`)
                setLoading(false);
                LoardRoles()
                setName('')
                setDescription('')

            } else {
                setLoading(false);
                toast.error(response.data.message);
            }
        }).catch((error) => {
            setLoading(false);
            toast.error(error.message);
        })
    }

    function New_Update(id:any) {
        let data = JSON.stringify({
            "name": name,
            "description": description
        });
        setLoading(true);
        api.getHttpRequest(`/roles/${id}`, data, 'PUT').then((response) => {
            if (response.data.status == 200) {

                toast.success(response.data.message);
                setLoading(false);
                LoardRoles()
                setName('')
                setDescription('')

            } else {
                setLoading(false);
                toast.error(response.data.message);
            }
        }).catch((error) => {
            setLoading(false);
            toast.error(error.message);
        })
    }

    const handleDelete = (value:any) => {
		api.getHttpRequest(`/roles/${value}`, data, 'delete').then((response) => {
            if (response.data.status == 201) {
                toast.success(response.data.message);
                setLoading(false);
                LoardRoles()
            } 
            else {
                setLoading(false);
                toast.error(response.data.message);
            }
        }).catch((error) => {
            setLoading(false);
            toast.error(error.message);
        })
          
	}

    /**End All button action */

    const showElement = (element: any) => {
        setName(element.name)
        setDescription(element.description)
        setId(element.id)
        setVBottom('Update')
    }

    //Formate Date
    function geDateFormat(dateString: string): string {
        const date = new Date(Date.parse(dateString));
        const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
        const formattedDate = date.toLocaleDateString('en-US', options);
        return formattedDate;
    }

    return (
        <>
            <div className=" show">
                <Toaster
                    position="bottom-left"

                />
                <div className="pt-3">
                    <div className="settings-form">
                        <h4 className="text-primary">New Role</h4>

                        <div>
                            <div className="row">
                                <input type="text" style={{display:'none'}}
                                 value={id}  />
                                <div className="mb-3 col-md-4">
                                    <label className="form-label">Designation</label>
                                    <input style={{ height: 35 }} type="text" placeholder="Designation role" className="form-control"
                                        name='name'
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                    />
                                </div>
                                <div className="mb-3 col-md-8">
                                    <label className="form-label">Description</label>
                                    <input style={{ height: 35 }} type='text' placeholder="Description" className="form-control"
                                        name='description'
                                        value={description}
                                        onChange={(e) => setDescription(e.target.value)}
                                    />
                                </div>

                            </div>
                            <div className="row">

                                <div className="mb-3 col-md-6">
                                    {!Loading && (
                                        vBottom ? <button onClick={() => New_Update(id)} type="submit" className="btn btn-primary">Update</button> :
                                        <button onClick={() => New_roles()} type="submit" className="btn btn-primary">Save</button>
                                    )}

                                    {Loading && (

                                        <button type="submit" className="btn btn-secondary" disabled>
                                            <i className="fa fa-spinner fa-spin fa-3x  fa-lg"></i> <span>Ongoing processing...</span>
                                        </button>
                                    )}
                                </div>


                            </div>




                        </div>
                    </div>
                    <div className="table-responsive mt-10">
                        <Card style={{ opacity: 0.4 }}>
                            <h4 className="card-title">List of roles</h4>
                        </Card>

                        <table className="table table-responsive-md">
                            <thead>
                                <tr>

                                    <th><strong>#</strong></th>
                                    <th><strong>Designation</strong></th>
                                    <th><strong>Description</strong></th>
                                    <th><strong>Date of creation</strong></th>
                                    <th><strong>Action</strong></th>

                                </tr>
                            </thead>
                            <tbody>
                                {

                                    loadRole.map((item, i) => (

                                        <tr key={i}>
                                            <td><strong>{item.id}</strong></td>

                                            <td>{item.name}</td>
                                            <td>{item.description}</td>
                                            <td><div className="d-flex align-items-center"> {geDateFormat(item.createdAt)}</div></td>
                                            <td>
                                                <div className="d-flex">
                                                    <div  className="btn btn-primary shadow btn-xs sharp me-1"  onClick={() => showElement(item)}><i className="fas fa-pencil-alt"></i></div>
                                                    <Popconfirm title={`Are you sure want to delete ${item.name} ?`} onConfirm={() => handleDelete(item.id)}>
                                                        {/* <button  className="btn btn-danger shadow btn-xs sharp"></button> */}
                                                        {/* <Button><i className="fas fa-trash-alt"></i></Button> */}
                                                        <DeleteOutlined style={{
                                                            paddingTop: 1, color: 'white', backgroundColor: 'red',
                                                            borderRadius: 10,
                                                            fontSize: 10,
                                                            padding: 8
                                                        }} />
                                                    </Popconfirm>
                                                </div>
                                            </td>
                                        </tr>

                                    ))


                                }

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Roles
