import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom";
import { Card, Col, Input, Modal, Row, Space, Tabs, Form, Radio, Button, Select } from 'antd';
import {
  SettingOutlined, SearchOutlined, InfoCircleOutlined, CloseOutlined
} from '@ant-design/icons'
import api from '../../config/Config';
import requestRow from '../../controller/PageRow';
import toast, { Toaster } from 'react-hot-toast';
import type { SelectProps } from 'antd';
import Country from "./country";
import CountrySelector from "./country1"
import Roles from "./roles";
import User from "./country";

type RequiredMark = boolean | 'optional';


const { Option } = Select;
interface Tag {
  id: string;
  name: string;
  code: string;

}

interface OptionType {
  value: string;
  label: string;
}


const Parametter = () => {
  const data = '';

  const navigate = useNavigate();

  const [country, setCountry] = useState<Tag[]>([])

  const [countries, setCountries] = useState<Tag[]>([]);
  const [selectedCountry, setSelectedCountry] = useState('');
  const [currencies, setCurrencies] = useState<Tag[]>([]);
  const [selectedCurrency, setSelectedCurrency] = useState<Tag[]>([]);
  const [languages, setLanguages] = useState<Tag[]>([]);
  const [selectedLanguage, setSelectedLanguage] = useState('');

  useEffect(() => {
    if (!localStorage.getItem('token') || localStorage.getItem('token') == null) {
      navigate('/signin')
    }

    api.getHttpRequest('/exemple/countrys', data, 'GET')
      .then(response => {
        setCountries(response.data);
      })
      .catch(err => console.log(err));

  }, [])

  const LoadCountry = () => {

    api.getHttpRequest("/exemple/countrys", data, 'GET').then((response) => {
      const result = response.data.data
      setCountry(result);

    }).catch((error) => {
      toast.error(error.message);
    })
  }

  const handleCountryChange = (value: string) => {
    setSelectedCountry(value);
    api.getHttpRequest(`/exemple/countrys/${value}`, data, 'GET')
      .then(res => {
        setCurrencies(res.data.currency);
        setLanguages(res.data.language);
      })
      .catch(err => console.log(err));
  };

  const [form] = Form.useForm();
  const [requiredMark, setRequiredMarkType] = useState<RequiredMark>('optional');

  const onRequiredTypeChange = ({ requiredMarkValue }: { requiredMarkValue: RequiredMark }) => {
    setRequiredMarkType(requiredMarkValue);
  };


  // const dataVoucher = country.map((item, i) => ({
  // 	...item,
  // 	key: i
  // }))

  // const options: SelectProps<requestRow> = [
  // 	{
  // 		title: "name",
  // 		dataIndex: "name",
  // 	},
  // ]

  const options = country.map(item => ({
    value: item.name,
    label: item.name,
  }));

  const handleChange = (value: any) => {
    console.log(`selected ${value}`);
    setCountry(value)
  };

  return (
    <>
      <div className="container-admin">
        <div className="page-container">

          <Space direction='vertical'>

            <Space style={{marginTop:12}} direction="horizontal">

              <SettingOutlined
                style={{
                  color: "green",
                  backgroundColor: "rgba(0,255,0,0.25)",
                  borderRadius: 20,
                  fontSize: 24,
                  padding: 8

                }}
              />

              <h3>Settings </h3>

            </Space>

            <div style={{ width: '100%', marginTop: 10 }}>
              <Card style={{opacity:0.9}}>
                <Tabs defaultActiveKey="tab1" tabPosition="top">

                  <Tabs.TabPane tab="Country registration" key="tab1">

                    <Country />

                  </Tabs.TabPane>

                  <Tabs.TabPane tab="Attribution roles" key="tab2">

                    <Roles/>

                  </Tabs.TabPane>

                  {/* <Tabs.TabPane tab="User Registration" key="tab3">

                    <User/>

                  </Tabs.TabPane> */}

                </Tabs>
              </Card>


            </div>




          </Space>




        </div>
      </div>
    </>
  )
}

export default Parametter