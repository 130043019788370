import { BrowserRouter, Routes, Route } from "react-router-dom"
import Home from '../../views/Home'
import Voucher from '../../views/vouchers/Voucher'
import Parametter from "../../views/Settings";
import VoucherSecondary from "../../views/vouchers/VoucherSecondary";

function AppRoutes() {
    return (

        <Routes>
            <Route path="/" element={<Home />}></Route>
            <Route path="/dashboard" element={<Home />}></Route>
            <Route path="/voucher" element={<Voucher />}></Route>
            <Route path="/settings" element={<Parametter/>}></Route>
            <Route path="/secondary/:valueserie" element={<VoucherSecondary/>}></Route>
        </Routes>

    )
}

export default AppRoutes;