import React, { useEffect, useState } from 'react'
import { Card, Col, Input, Modal, Row, Space, Tabs, Form, Radio, Button, Select, Popconfirm } from 'antd';
import {
    SettingOutlined, SearchOutlined, InfoCircleOutlined, CloseOutlined, DeleteOutlined
} from '@ant-design/icons'
import { useNavigate } from 'react-router-dom';
import api, { geDateFormat } from '../../../config/Config';
import requestRow from '../../../controller/PageRow';
import toast, { Toaster } from 'react-hot-toast';


const User = () => {

    const data = '';

    const navigate = useNavigate();

    const [Loading, setLoading] = useState(false);
    const [countries, setCountries] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState('');
    const [currencies, setCurrencies] = useState('');
    const [selectedCurrency, setSelectedCurrency] = useState('');
    const [languages, setLanguages] = useState('');
    const [selectedLanguage, setSelectedLanguage] = useState('');
    const [getCountryId, setCountryId] = useState('');
    const [getImage, setImage] = useState('')
    const [description, setDescription] = useState('')
    const [apiUrl, setApiUrl] = useState('')
    const [urlWeb, setUrlWeb] = useState('')
    const [adminPassword, setAdminPassword] = useState('')
    const [adminUser, setAdminUser] = useState('')
    const [exchangCover, setExchangCover] = useState('')
    const [loadCountryRegister, setloadCountryRegister] = useState([])

    const [id, setId] = useState('');
    const [vBottom, setVBottom] = useState('');

    const [nameCountry, steNameCountry] = useState('')
    const [currency, setCurrency] = useState('')
    const [language, setLanguage] = useState('')
    const [states, setStates] = useState([])

    const ChangeCountry = (event) => {
        steNameCountry(event.target.value)
        const country = countries.find((ctr) => ctr.name === event.target.value);
        if (country) {
            //   setStates([country.currency]);
        }


    }

    useEffect(() => {
        if (!localStorage.getItem('token') || localStorage.getItem('token') == null) {
            navigate('/signin')
        }

        LoardCountry();
        loadCountry_Register();

    }, [])

    const LoardCountry = () => {
        api.getHttpRequest('/exemple/countrys', data, 'GET')
            .then(response => {
                setCountries(response.data.data);
            })
            .catch((error) => {
                toast.error(error.message);
            })
    }

    const loadCountry_Register = () => {
        api.getHttpRequest("/country", data, 'GET').then((response) => {
            const result = response.data.data
            setloadCountryRegister(result);

        }).catch((error) => {
            toast.error(error.message);
        })
    }



    const handleCountry = (event) => {
        const getCountry = event.target.value;

        const filterCountry = countries.find((item) => item.name === getCountry);
        if (filterCountry) {
            setSelectedCurrency(filterCountry.currency.code);
            setSelectedLanguage(filterCountry.language.name);

            // setImage(filterCountry.flag)
            setSelectedCountry(getCountry);
        }
    };

    /**Action Buttom */

    function add_contruies() {
        let data = JSON.stringify({
            "name": selectedCountry,
            "description": description,
            "url_api": apiUrl,
            "url_web": urlWeb,
            "admin_password": adminPassword,
            "admin_username": adminUser,
            "currency": selectedCurrency,
            "exchang_cover": exchangCover,
            "language": selectedLanguage,
            "flag": getImage,

        });
        setLoading(true);
        api.getHttpRequest('/country', data, 'POST').then((response) => {
            if (response.data.status == 201) {
                toast.success(response.data.message);
                setLoading(false);
                loadCountry_Register()
            } else if (response.data.status === 400) {
                toast.error(response.data.message);
            }
            else {
                setLoading(false);
                toast.error(response.data.message);
            }
        }).catch((error) => {
            setLoading(false);
            toast.error(error.message);
        })
    }

    function Update_contruies(id) {
        let data = JSON.stringify({
            "name": selectedCountry,
            "description": description,
            "url_api": apiUrl,
            "url_web": urlWeb,
            "admin_password": adminPassword,
            "admin_username": adminUser,
            "currency": selectedCurrency,
            "exchang_cover": exchangCover,
            "language": selectedLanguage,
            "flag": getImage,

        });
        setLoading(true);
        api.getHttpRequest(`/country/${id}`, data, 'POST').then((response) => {
            if (response.data.status == 201) {
                toast.success(response.data.message);
                setLoading(false);
                loadCountry_Register()
            } else if (response.data.status === 400) {
                toast.error(response.data.message);
            }
            else {
                setLoading(false);
                toast.error(response.data.message);
            }
        }).catch((error) => {
            setLoading(false);
            toast.error(error.message);
        })
    }

    const handleDelete = (value) => {
        api.getHttpRequest(`/country/${value}`, data, 'delete').then((response) => {
            if (response.data.status == 201) {
                toast.success(response.data.message);
                setLoading(false);
                loadCountry_Register()
            }
            else {
                setLoading(false);
                toast.error(response.data.message);
            }
        }).catch((error) => {
            setLoading(false);
            toast.error(error.message);
        })

    }


    const showElement = (element) => {
        setSelectedCountry(element.name)
        setSelectedCurrency(element.currency)
        setSelectedLanguage(element.langue)
        setDescription(element.description)
        setExchangCover(element.exchang_cover)
        setImage(element.flag)
        setUrlWeb(element.url_web)
        setApiUrl(element.url_api)
        setId(element.id)
        setAdminUser(element.admin_user)
        setVBottom('Update')
    }
    /** End Action Buttom */


    function geDateFormat(dateString) {
        const date = new Date(Date.parse(dateString));
        const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
        const formattedDate = date.toLocaleDateString('en-US', options);
        return formattedDate;
    }


    return (
        <>
            <div className=" show">

                <div className="pt-3">
                    <Toaster
                        position="bottom-left"

                    />
                    <div className="settings-form">
                        <h4 className="text-primary">New Countries</h4>
                        <div>
                            <div className="row">
                                <input type="text"
                                    value={id} style={{ display: 'none' }} />

                                <div className="mb-3 col-md-3">
                                    <label className="form-label">Countries</label>
                                    <select style={{ height: 30, width: 200, color: 'black' }} className="form-control default-select wide"
                                        onChange={(e) => handleCountry(e)}
                                        value={selectedCountry}
                                    >
                                        <option >Choose...</option>
                                        {countries.map((item, i) => (
                                            <option key={i} value={item.name}>
                                                {item.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="mb-3 col-md-3">
                                    <label className="form-label">Currency</label>
                                    <input style={{ height: 30, color: 'black' }} type="text" placeholder="Currency" className="form-control" value={selectedCurrency} readOnly
                                        name='selectedCurrency'
                                        onChange={(e) => setSelectedCurrency(e.target.value)}
                                    />
                                </div>
                                <div className="mb-3 col-md-3">
                                    <label className="form-label">Language</label>
                                    <input style={{ height: 30, color: 'black' }} type="text" placeholder="Language" className="form-control" value={selectedLanguage} readOnly
                                        name='selectedLanguage'
                                        onChange={(e) => setSelectedLanguage(e.target.value)}
                                    />
                                </div>

                                <div className="mb-3 col-md-3">
                                    <label className="form-label">Exchang Cover</label>
                                    <input style={{ height: 30, color: 'black' }} type="text" placeholder="Exchang cover" className="form-control"
                                        name='exchangCover'
                                        value={exchangCover}
                                        onChange={(e) => setExchangCover(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="row">


                                <div className="mb-3 col-md-3">
                                    <label className="form-label">Admin username</label>
                                    <input style={{ height: 30, color: 'black' }} type="text" placeholder="Admin username" className="form-control"
                                        name='adminUser'
                                        value={adminUser}
                                        onChange={(e) => setAdminUser(e.target.value)}
                                    />
                                </div>

                                <div className="mb-3 col-md-3">
                                    <label className="form-label">Admin password</label>
                                    <input style={{ height: 30, color: 'black' }} type="text" placeholder="Admin password" className="form-control"
                                        name='adminPassword'
                                        onChange={(e) => setAdminPassword(e.target.value)}
                                    />
                                </div>

                                <div className="mb-3 col-md-3">
                                    <label className="form-label">Url Image</label>
                                    <input style={{ height: 30, color: 'black' }} type="url" placeholder="Url image" className="form-control"
                                        name='getImage'
                                        value={getImage}
                                        onChange={(e) => setImage(e.target.value)}
                                    />
                                </div>
                                <div className="mb-3 col-md-3">
                                    <label className="form-label">Url Web</label>
                                    <input style={{ height: 30, color: 'black' }} type="url" placeholder="Url web manager" className="form-control"
                                        name='urlWeb'
                                        value={urlWeb}
                                        onChange={(e) => setUrlWeb(e.target.value)}
                                    />
                                </div>

                            </div>

                            <div className="row">
                                <div className="mb-3 col-md-3">
                                    <label className="form-label">Url Api</label>
                                    <input style={{ height: 30, color: 'black' }} type="url" placeholder="Url api" className="form-control"
                                        value={apiUrl}
                                        onChange={(e) => setApiUrl(e.target.value)} name='apiUrl'
                                    />
                                </div>
                                <div className="mb-3 col-md-6">
                                    <label className="form-label">Description</label>
                                    <input style={{ height: 30, color: 'black' }}
                                        value={description}
                                        onChange={(e) => setDescription(e.target.value)} name='description' type="text" placeholder="Url api" className="form-control" />
                                </div>

                                <div className="mb-3 col-md-3">
                                    <label className="form-label" style={{ color: 'white' }} >button</label><br></br>
                                    {!Loading && (
                                        vBottom ? <button style={{ width: 150, height: 30, padding: 2 }} onClick={() => Update_contruies(id)} type="submit" className="btn btn-primary">Update</button> :

                                            <button style={{ width: 150, height: 30, padding: 2 }} onClick={() => add_contruies()} type="submit" className="btn btn-primary">Submit</button>
                                    )}

                                    {Loading && (

                                        <button style={{ width: 150, height: 30, padding: 2 }} type="submit" className="btn btn-secondary" disabled>
                                            <i className="fa fa-spinner fa-spin fa-3x  fa-lg"></i> <span>Ongoing processing...</span>
                                        </button>
                                    )}

                                </div>
                            </div>







                        </div>
                    </div>
                    <div className="table-responsive mt-20">
                        <Card style={{ opacity: 0.4 }}>
                            <h4 className="card-title">Countries List</h4>
                        </Card>

                        <table className="table table-responsive-md">
                            <thead>
                                <tr>
                                    <th><strong>#</strong></th>
                                    <th><strong>NAME</strong></th>
                                    <th><strong>Currency</strong></th>
                                    <th><strong>Exchang Cover</strong></th>
                                    <th><strong>Language</strong></th>
                                    <th><strong>Date of creation</strong></th>

                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    loadCountryRegister.map((item, i) => (

                                        <tr key={i}>
                                            <td><strong>{item.id}</strong></td>
                                            <td>{item.name}</td>
                                            <td>{item.currency}</td>
                                            <td>{item.exchang_cover}</td>
                                            <td>{item.langue}</td>
                                            <td>{geDateFormat(item.createdAt)}</td>
                                            <td>
                                                <div className="d-flex">
                                                    <div className="btn btn-primary shadow btn-xs sharp me-1" onClick={() => showElement(item)}>
                                                        <i className="fas fa-pencil-alt"></i></div>

                                                    <Popconfirm title={`Are you sure want to delete ${item.name} ?`} onConfirm={() => handleDelete(item.id)}>
                                                        {/* <button  className="btn btn-danger shadow btn-xs sharp"></button> */}
                                                        {/* <Button><i className="fas fa-trash-alt"></i></Button> */}
                                                        <DeleteOutlined style={{
                                                            paddingTop: 1, color: 'white', backgroundColor: 'red',
                                                            borderRadius: 10,
                                                            fontSize: 10,
                                                            padding: 8
                                                        }} />
                                                    </Popconfirm>

                                                </div>
                                            </td>
                                        </tr>

                                    ))
                                }


                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}

export default User
